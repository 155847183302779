export const ChartOptionsData = {
  Curr: [
    { name: "Jul 1, 2022", x: 1656633600000, y: 45 },
    { name: "Jul 2, 2022", x: 1656720000000, y: 20 },
    { name: "Jul 3, 2022", x: 1656806400000, y: 30 },
    { name: "Jul 4, 2022", x: 1656892800000, y: 25 },
    { name: "Jul 5, 2022", x: 1656979200000, y: 35 },
    { name: "Jul 6, 2022", x: 1657065600000, y: 40 },
    { name: "Jul 7, 2022", x: 1657152000000, y: 50 },
    { name: "Jul 8, 2022", x: 1657238400000, y: 55 },
    { name: "Jul 9, 2022", x: 1657324800000, y: 60 },
  ],
  Exp: [
    { name: "Jul 1, 2022", x: 1656633600000, y: 50 },
    { name: "Jul 2, 2022", x: 1656720000000, y: 25 },
    { name: "Jul 3, 2022", x: 1656806400000, y: 35 },
    { name: "Jul 4, 2022", x: 1656892800000, y: 40 },
    { name: "Jul 5, 2022", x: 1656979200000, y: 45 },
    { name: "Jul 6, 2022", x: 1657065600000, y: 50 },
    { name: "Jul 7, 2022", x: 1657152000000, y: 55 },
    { name: "Jul 8, 2022", x: 1657238400000, y: 60 },
    { name: "Jul 9, 2022", x: 1657324800000, y: 65 },
  ]
};
