import React from 'react';

const FeaturesComparison = () => {
  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="border border-gray-300 px-4 py-2">Features</th>
            <th className="border border-gray-300 px-4 py-2">Core</th>
            <th className="border border-gray-300 px-4 py-2">Stock</th>
          </tr>
        </thead>
        <tbody>
        <tr className="bg-white">
            <td className="border border-gray-300 px-4 py-2">Stock Tools</td>
            <td className="border border-gray-300 px-4 py-2 bg-green-500">Available</td>
            <td className="border border-gray-300 px-4 py-2  bg-green-500">Available</td>
          </tr>
          <tr className="bg-white">
            <td className="border border-gray-300 px-4 py-2">Export and Download</td>
            <td className="border border-gray-300 px-4 py-2  bg-green-500">Available</td>
            <td className="border border-gray-300 px-4 py-2  bg-green-500">Available</td>
          </tr>
          <tr className="bg-white">
            <td className="border border-gray-300 px-4 py-2">Range Selector <small>(Period and Date)</small></td>
            <td className="border border-gray-300 px-4 py-2 bg-red-500">Not available</td>
            <td className="border border-gray-300 px-4 py-2  bg-green-500">Available</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="border border-gray-300 px-4 py-2">Navigator <small>(Mini map)</small></td>
            <td className="border border-gray-300 px-4 py-2 bg-red-500">Not available</td>
            <td className="border border-gray-300 px-4 py-2  bg-green-500">Available</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { FeaturesComparison };