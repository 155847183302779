import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChartsDisplayLayout } from "./components/ChartsDisplayLayout/ChartsDisplayLayout";
import { NotFound } from "./components/NotFound/NotFound";
import { CapabilityShowcase } from "./components/CapabilityShowcase/CapabilityShowcase";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ChartsDisplayLayout />} />
          <Route path="/highcharts" element={<ChartsDisplayLayout />} />
          <Route path="/sabre-poc" element={<CapabilityShowcase />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
