import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import Exporting from "highcharts/modules/exporting.js"

Exporting(Highcharts);

window.Highcharts = Highcharts;

const TimeSlider = () => {
  useEffect(() => {
    // Check if the script is already added
    const existingScript = document.querySelector('script[src="https://cdn.jsdelivr.net/gh/larsac07/Motion-Highcharts-Plugin/motion.js"]');
    if (!existingScript) {
      // Dynamically load the motion.js script
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/gh/larsac07/Motion-Highcharts-Plugin/motion.js';
      script.async = true;
      script.onload = () => {
        Highcharts.chart('time-slider-1234', {
          chart: {
            type: 'bar'
          },
          title: {
            text: 'Fruit Consumption'
          },
          xAxis: {
            categories: ['Apples', 'Bananas', 'Oranges']
          },
          yAxis: {
            title: {
              text: 'Fruit eaten'
            }
          },
          motion: { //This is custom HighCharts plugin
            enabled: true,
            axisLabel: 'year',
            labels: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010],
            series: [0, 1],
            updateInterval: 20,
            magnet: {
              type: 'both',
              round: 'floor',
              smoothThumb: true,
              step: 0.01
            }
          },
          series: [{
            name: 'Jane',
            data: [
              { sequence: [1, 5, 2, 7, 7, 1, 6, 9, 2, 5, 1] },
              { sequence: [5, 5, 7, 7, 3, 8, 9, 9, 1, 7, 3] },
              { sequence: [7, 1, 9, 3, 7, 4, 3, 8, 7, 1, 4] }
            ]
          }, {
            name: 'John',
            data: [
              { sequence: [7, 1, 9, 3, 7, 4, 3, 8, 7, 1, 4] },
              { sequence: [1, 5, 2, 7, 7, 1, 6, 9, 2, 5, 1] },
              { sequence: [9, 1, 7, 3, 3, 2, 1, 4, 5, 6, 7] }
            ]
          }]
        });
      };
      document.body.appendChild(script);
    } else {
      // Initialize the Highcharts chart if the script is already loaded
      Highcharts.chart('time-slider-1234', {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'Fruit Consumption'
        },
        xAxis: {
          categories: ['Apples', 'Bananas', 'Oranges']
        },
        yAxis: {
          title: {
            text: 'Fruit eaten'
          }
        },
        motion: {
          enabled: true,
          axisLabel: 'year',
          labels: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010],
          series: [0, 1],
          updateInterval: 20,
          magnet: {
            type: 'both',
            round: 'floor',
            smoothThumb: true,
            step: 0.01
          }
        },
        series: [{
          name: 'Jane',
          data: [
            { sequence: [1, 5, 2, 7, 7, 1, 6, 9, 2, 5, 1] },
            { sequence: [5, 5, 7, 7, 3, 8, 9, 9, 1, 7, 3] },
            { sequence: [7, 1, 9, 3, 7, 4, 3, 8, 7, 1, 4] }
          ]
        }, {
          name: 'John',
          data: [
            { sequence: [7, 1, 9, 3, 7, 4, 3, 8, 7, 1, 4] },
            { sequence: [1, 5, 2, 7, 7, 1, 6, 9, 2, 5, 1] },
            { sequence: [9, 1, 7, 3, 3, 2, 1, 4, 5, 6, 7] }
          ]
        }]
      });
    }

    return () => {
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <div id="time-slider-1234"></div>
  );
};

export { TimeSlider };
