import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

function generateData(seriesName, years) {
    let data = [];
    for (let year = 1; year <= years; year++) {
        for (let month = 6; month <= 8; month++) { // July (6), August (7), September (8)
            for (let day = 1; day <= 31; day++) {
                let date = new Date(2022 + year - 1, month, day).getTime();

                let currValue = Math.floor(Math.random() * 61);  // Random data between 0 and 60
                let expValue = Math.floor(Math.random() * 61);   // Random data between 0 and 60
                let currLYValue = currValue + Math.floor(Math.random() * 61) + 1;  // Random data between currValue and 121
                let finalLYValue = expValue + Math.floor(Math.random() * 61) + 1;  // Random data between expValue and 121

                if (seriesName === 'Curr') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: currValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                } else if (seriesName === 'Exp') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: expValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                } else if (seriesName === 'Curr LY') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: currLYValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                } else if (seriesName === 'Final LY') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: finalLYValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                }
            }
        }
    }
    return data;
}

const options = {
    chart: {
        type: 'column',
        alignTicks: false,
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
            borderRadius: localStorage.getItem('borderRadius') ? parseInt(localStorage.getItem('borderRadius')) : 0
        }
    },
    rangeSelector: {
        enabled: true,
        selected: 0
    },
    series: [
        {
            name: 'Curr',
            data: generateData('Curr', 1),
            color: localStorage.getItem('CurrColor') || '#23687D',
            pointPadding: 0.3,
            pointPlacement: -0.2
        },
        {
            name: 'Curr LY',
            data: generateData('Curr LY', 1),
            color: localStorage.getItem('CurrLYColor') || '#74BFD7',
            linkedTo: ':previous',
            grouping: false,
            pointPadding: 0.4,
            pointPlacement: -0.2
        },
        {
            name: 'Exp',
            data: generateData('Exp', 1),
            color: localStorage.getItem('ExpColor') || '#23687D',
            pointPadding: 0.3,
            pointPlacement: 0.2
        },
        {
            name: 'Final LY',
            data: generateData('Final LY', 1),
            color: localStorage.getItem('FinalLYColor') || '#74BFD7',
            linkedTo: ':previous',
            grouping: false,
            pointPadding: 0.4,
            pointPlacement: 0.2
        }
    ],
    title: {
        text: ''
    },
    stockTools: {
        enabled: false,
        gui: {
            buttons: []
        }
    },
    tooltip: {
        shared: false,
        valueSuffix: '%',
        xDateFormat: '%b %e, %Y'
    },
    xAxis: {
        type: 'datetime',
        labels: {
            formatter: function () {
                const date = new Date(this.value);
                const hours = date.getUTCHours();
                const day = date.getUTCDate();

                if (hours === 0 && day === 1) {
                    return Highcharts.dateFormat('%b', this.value);
                } else if (hours === 0) {
                    return Highcharts.dateFormat('%e', this.value);
                } else {
                    return Highcharts.dateFormat('%H:%M', this.value);
                }
            }
        }
    },
    yAxis: [
        {
            opposite: false,
            min: 0,
            max: 120,
            title: {
                text: 'Seat factor (%)'
            }
        },
        {
            opposite: true,
            min: 0,
            max: 120,
            title: {
                text: 'No show'
            }
        }
    ],
}

const CustomizableComponent = () => {
    const [selectedSeries, setSelectedSeries] = useState('Curr');
    const [selectedColor, setSelectedColor] = useState(localStorage.getItem(`${selectedSeries}Color`) || '#23687D');
    const [borderRadius, setBorderRadius] = useState(localStorage.getItem('borderRadius') ? parseInt(localStorage.getItem('borderRadius')) : 0);
    const [chartOptions, setChartOptions] = useState(options);



    useEffect(() => {
        setSelectedColor(localStorage.getItem(`${selectedSeries}Color`) || '#23687D');
    }, [selectedSeries]);

    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setSelectedColor(newColor);
        localStorage.setItem(`${selectedSeries}Color`, newColor);

        setChartOptions(prevOptions => {
            const newSeries = prevOptions.series.map(series => {
                if (series.name === selectedSeries) {
                    return { ...series, color: newColor };
                }
                return series;
            });
            return { ...prevOptions, series: newSeries };
        });
    };

    const handleBorderRadiusChange = (e) => {
        const newBorderRadius = parseInt(e.target.value);
        setBorderRadius(newBorderRadius);
        localStorage.setItem('borderRadius', newBorderRadius);
        setChartOptions(prevOptions => ({
            ...prevOptions,
            plotOptions: {
                ...prevOptions.plotOptions,
                column: {
                    ...prevOptions.plotOptions.column,
                    borderRadius: newBorderRadius
                }
            }
        }));
    };

    return (
        <div>
            <div className="border p-4 mb-4">
                <div className="flex items-center justify-start gap-2 mb-4">
                    <div className="relative border p-2 rounded flex flex-row gap-2">
                        <label className="absolute -top-2 bg-white px-1 text-xs">Color</label>
                        <select
                            value={selectedSeries}
                            onChange={e => setSelectedSeries(e.target.value)}
                            className="p-2 border rounded"
                        >
                            <option value="Curr">Curr</option>
                            <option value="Curr LY">Curr LY</option>
                            <option value="Exp">Exp</option>
                            <option value="Final LY">Final LY</option>
                        </select>
                        <div className="flex items-center">
                            <input
                                type="color"
                                value={selectedColor}
                                onChange={handleColorChange}
                                className="p-2 border rounded"
                                style={{ width: '40px', height: '40px' }}
                            />
                        </div>
                    </div>
                    <div className="border p-2 rounded relative w-36">
                        <label className="absolute -top-2 bg-white px-1 text-xs">Border Radius</label>
                        <input type="number" value={borderRadius} onChange={handleBorderRadiusChange} placeholder="Border Radius" className="p-2 w-32 text-sm" />
                    </div>
                </div>
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={chartOptions}
                    immutable={false}
                />
            </div>
        </div>
    );
};

export { CustomizableComponent };
