import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const options = {
    chart: {
        type: 'column',
        zooming: {
            type: 'x' // Y Or xy
        }
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['Fashion', 'Furniture', 'Books', 'Stationary', 'Food and Beverages', 'Shoes', 'Toys', 'Automobiles', 'Mobiles', 'Appliances', 'Sports', 'Electronics'],
        crosshair: true
    },
    yAxis: [{
        labels: {
            format: '{value} Cr',
        },
        title: {
            text: 'Sale volume'
        }
    }, {
        labels: {
            format: '{value} Cr',
        },
        title: {
            text: 'Revenue'
        },
        opposite: true

    }, {
        labels: {
            format: '{value} Cr',
        },
        title: {
            text: 'Ad expense'
        },
        opposite: true
    }],
    tooltip: {
        shared: true
    },
    series: [{
        type: 'column',
        name: 'Sales',
        data: [15, 7, 13, 6, 4, 5, 7, 3, 16, 4, 5, 8],
        yAxis: 0,
        tooltip: {
            valueSuffix: " Cr"
        },
    }, {
        type: 'line',
        name: 'Revenue',
        data: [9, 2, 5, 1, 2, 0, 3, 0, 7, 2, 0, 5],
        yAxis: 1,
        tooltip: {
            valueSuffix: " Cr"
        }
    }, {
        type: 'line',
        name: 'Ad Expense',
        data: [2, 4, 3, 0, 2, 1, 4, 5, 8, 1, 0, 3],
        yAxis: 2,
        tooltip: {
            valueSuffix: " Cr"
        }
    }]
};

const MultiAxis = () => {
    return <HighchartsReact highcharts={Highcharts} options={options} />
}

export { MultiAxis }