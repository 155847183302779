import { ExportChart } from "../ExportChart/ExportChart";
import { DraggableCurve } from "../DraggableCurve/DraggableCurve";
import { FeaturesComparison } from "../FeaturesComparison/FeaturesComparison";
import { DrawingOnChart } from "../DrawingOnChart/DrawingOnChart";

const CapabilityShowcase = () => {
  return (
    <div className="container mx-auto">
      <h1 className="text-center mt-10">Highcharts POC</h1>

      <ExportChart
        stockToolsButtons={["simpleShapes"]}
        enabledStockTools={true}
      />

      {/* <h2 className="mt-16 mb-4">Feature availability on charts</h2>
      <FeaturesComparison /> */}

      <h1 className="mt-16">Additional Capabilities</h1>
      <h2 className="my-4">Draggable Curve</h2>
      <DraggableCurve />
    </div>
  );
};

export { CapabilityShowcase };
