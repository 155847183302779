import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";
import Accessibility from "highcharts/modules/accessibility.js";
import Exporting from "highcharts/modules/exporting.js"
import ExportingData from "highcharts/modules/export-data.js"
import Hollowcandlestick from "highcharts/modules/hollowcandlestick.js";
import Heikinashi from "highcharts/modules/heikinashi.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Navigator from "highcharts/modules/navigator.js";

// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);
Exporting(Highcharts);
ExportingData(Highcharts);
Drilldown(Highcharts);
Accessibility(Highcharts);
Hollowcandlestick(Highcharts);
Heikinashi(Highcharts);
Navigator(Highcharts);

function generateData(seriesName, years) {
    let data = [];
    for (let year = 1; year <= years; year++) {
        for (let month = 6; month <= 8; month++) { // July (6), August (7), September (8)
            for (let day = 1; day <= 31; day++) {
                let date = new Date(2022 + year - 1, month, day).getTime();

                let currValue = Math.floor(Math.random() * 61);  // Random data between 0 and 60
                let expValue = Math.floor(Math.random() * 61);   // Random data between 0 and 60
                let currLYValue = currValue + Math.floor(Math.random() * 61) + 1;  // Random data between currValue and 121
                let finalLYValue = expValue + Math.floor(Math.random() * 61) + 1;  // Random data between expValue and 121

                if (seriesName === 'Curr') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: currValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                } else if (seriesName === 'Exp') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: expValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                } else if (seriesName === 'Curr LY') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: currLYValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                } else if (seriesName === 'Final LY') {
                    data.push({
                        name: Highcharts.dateFormat('%b %e, %Y', date),
                        x: date,
                        y: finalLYValue,
                        drilldown: `year${year}month${month}day${day}-hours`
                    });
                }
            }
        }
    }
    return data;
}

function generateDrilldownData(years) {
    let drilldownSeries = [];
    for (let year = 1; year <= years; year++) {
        for (let month = 6; month <= 8; month++) { // July (6), August (7), September (8)
            for (let day = 1; day <= 31; day++) {
                let hourlyData = [];
                let dayDate = new Date(2022 + year - 1, month, day);
                for (let hour = 0; hour < 24; hour++) {
                    let hourDate = new Date(dayDate.getTime() + hour * 3600 * 1000).getTime();
                    hourlyData.push({
                        name: Highcharts.dateFormat('%H:00', hourDate),
                        x: hourDate,
                        y: Math.floor(Math.random() * 121),
                        drilldown: `year${year}month${month}day${day}hour${hour}-minutes`
                    });
                }
                drilldownSeries.push({
                    id: `year${year}month${month}day${day}-hours`,
                    data: hourlyData
                });

                for (let hour = 0; hour < 24; hour++) {
                    let minuteData = [];
                    for (let minute = 0; minute < 60; minute++) {
                        let minuteDate = new Date(dayDate.getTime() + hour * 3600 * 1000 + minute * 60 * 1000).getTime();
                        minuteData.push([minuteDate, Math.floor(Math.random() * 121)]);
                    }
                    drilldownSeries.push({
                        id: `year${year}month${month}day${day}hour${hour}-minutes`,
                        data: minuteData
                    });
                }
            }
        }
    }
    return drilldownSeries;
}

const BarChart = ({
    isNavigatorEnabled = false,
    allowMouseWheelZoom = false,
    stockToolsButtons = [],
    enabledStockTools = false
}) => {

    const chartOptions = {
        chart: {
            type: 'column',
            alignTicks: false,
            zooming: {
                type: "x",
                resetButton: {
                    position: {
                        align: "right",
                        x: 0,
                        y: 0
                    },
                },
                mouseWheel: {
                    enabled: allowMouseWheelZoom
                }
            }
        },
        drilldown: {
            series: generateDrilldownData(3)
        },
        lang: {
            resetZoomTitle: "Reset zoom"
        },
        legend: {
            enabled: true
        },
        navigator: {
            enabled: isNavigatorEnabled,
            xAxis: {
                width: 300,
                left: '82.5%',
            }
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
            }
        },
        rangeSelector: {
            enabled: true,
            selected: 0
        },
        series: [
            {
                name: 'Curr',
                data: generateData('Curr', 3),
                color: '#23687D',
                pointPadding: 0.3,
                pointPlacement: -0.2
            },
            {
                name: 'Curr LY',
                data: generateData('Curr LY', 3),
                color: '#74BFD7',
                linkedTo: ':previous',
                grouping: false,
                pointPadding: 0.4,
                pointPlacement: -0.2
            },
            {
                name: 'Exp',
                data: generateData('Exp', 3),
                color: '#23687D',
                pointPadding: 0.3,
                pointPlacement: 0.2
            },
            {
                name: 'Final LY',
                data: generateData('Final LY', 3),
                color: '#74BFD7',
                linkedTo: ':previous',
                grouping: false,
                pointPadding: 0.4,
                pointPlacement: 0.2
            }
        ],
        stockTools: {
            enabled: enabledStockTools,
            gui: {
                buttons: stockToolsButtons
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            shared: false,
            valueSuffix: '%',
            xDateFormat: '%b %e, %Y'
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    const date = new Date(this.value);
                    const hours = date.getUTCHours();
                    const day = date.getUTCDate();

                    if (hours === 0 && day === 1) {
                        // Monthly label format
                        return Highcharts.dateFormat('%b', this.value);
                    } else if (hours === 0) {
                        // Day label format
                        return Highcharts.dateFormat('%e', this.value);
                    } else {
                        // Hour label format
                        return Highcharts.dateFormat('%H:%M', this.value);
                    }
                }
            }
        },
        yAxis: [
            {
                opposite: false,
                min: 0,
                max: 120,
                title: {
                    text: 'Seat factor (%)'
                }
            },
            {
                opposite: true,
                min: 0,
                max: 120,
                title: {
                    text: 'No show'
                }
            }
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={"chart"}
            options={chartOptions}
            immutable={false}
        />
    );
};

export { BarChart };
