import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost.js';

Boost(Highcharts);


function getData(n) {
    const arr = [];
    let a,
        b,
        c,
        spike;
    for (let i = 0; i < n; i = i + 1) {
        if (i % 100 === 0) {
            a = 2 * Math.random();
        }
        if (i % 1000 === 0) {
            b = 2 * Math.random();
        }
        if (i % 10000 === 0) {
            c = 2 * Math.random();
        }
        if (i % 50000 === 0) {
            spike = 10;
        } else {
            spike = 0;
        }
        arr.push([
            i,
            2 * Math.sin(i / 100) + a + b + c + spike + Math.random()
        ]);
    }
    return arr;
}

const LargeVolumeDataPointChart = () => {
    const data = getData(500000);
    const chartOptions = {
        chart: {
            type: 'column',
            zooming: {
                type: 'x'
            },
            panning: true,
            panKey: 'shift'
        },

        title: {
            text: 'Highcharts drawing 500K data points (2.3 sec avg)'
        },
        stockTools: {
            enabled: false,
            gui: {
                buttons: []
            }
        },
        tooltip: {
            valueDecimals: 2
        },

        series: [{
            data: data
        }],
        boost: {
            useGPUTranslations: true,  // Enables GPU translations for better performance
            usePreAllocated: true      // Uses pre-allocated memory for speed
        },
    }
    
    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={"chart"}
            options={chartOptions}
            immutable={false}
        />
    );
};

export { LargeVolumeDataPointChart };