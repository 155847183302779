import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';

const SwitchingCharts = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);
  const [activeChart, setActiveChart] = useState('bar');

  useEffect(() => {
    if (!chartContainer.current) return;

    chartInstance.current = Highcharts.chart(chartContainer.current, {
      title: { text: '' },
      series: [{
        type: 'bar',
        data: [29.9, 71.5, 106.4]
      }]
    });

    const columnButton = document.getElementById('column');
    const barButton = document.getElementById('bar');
    const lineButton = document.getElementById('line');

    const updateChart = (type) => {
      if (chartInstance.current?.series?.length) {
        chartInstance.current.series[0].remove();
      }
      const isBarChart = type === 'bar';
      chartInstance.current.update({
        chart: {
          inverted: isBarChart
        },
        series: [{
          type: type,
          data: [29.9, 71.5, 106.4]
        }]
      }, true, true);
      setActiveChart(type);
    };

    columnButton.addEventListener('click', () => updateChart('column'));
    barButton.addEventListener('click', () => updateChart('bar'));
    lineButton.addEventListener('click', () => updateChart('line'));

    return () => {
      columnButton.removeEventListener('click', () => { });
      barButton.removeEventListener('click', () => { });
      lineButton.removeEventListener('click', () => { });
    };
  }, []);

  return (
    <div>
      <div ref={chartContainer}></div>
      <div className="flex gap-2 justify-center">
        <button
          id="bar"
          className={`autocompare ${activeChart === 'bar' ? 'bg-blue-700' : 'bg-blue-500'} hover:bg-blue-600 text-white font-bold py-2 px-4 rounded`}
        >
          Bar
        </button>
        <button
          id="column"
          className={`autocompare ${activeChart === 'column' ? 'bg-blue-700' : 'bg-blue-500'} hover:bg-blue-600 text-white font-bold py-2 px-4 rounded`}
        >
          Column
        </button>
        <button
          id="line"
          className={`autocompare ${activeChart === 'line' ? 'bg-blue-700' : 'bg-blue-500'} hover:bg-blue-600 text-white font-bold py-2 px-4 rounded`}
        >
          Line
        </button>
      </div>
    </div>
  );
};

export { SwitchingCharts };
