import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const chartOptions = {
    xAxis: {
        type: 'datetime'
    },
    title: {
        text: ''
    },
    tooltip: {
        shared: false,
        useHTML: true,
        stickOnContact: true,
        className: "tooltip-csq8F5kS",
        pointFormat:
            '<a style="cursor: pointer; text-decoration: underline; color: blue; margin-right: 4px;" rel="noreferrer" href="https://www.highcharts.com/" target="_blank">Link</a><br />' +
            '<button class="custom-button1" style="margin-top: 4px; margin-right: 4px; border-radius: 4px; color: white; padding: 4px;">Button 1</button>' +
            '<button class="custom-button2" style="border-radius: 4px; color: white; padding: 4px;">Button 2</button>' +
            '<table onmousedown="event.stopPropagation(); class="tooltip-table">' +
            '<tr><th colspan="2">{point.key}</th></tr>' +
            '<tr><td style="color: {series.color}">{series.name}</td>' +
            '<td style="text-align: right"><b>{point.y} EUR</b></td></tr>' +
            '</table>' +
            '<ul onmousedown="event.stopPropagation();" style="list-style-type: disc; padding-left: 20px; margin: 0; padding-left: 1.5em; text-align: left;">' +
            '<li>Detail 1: {point.detail1}</li>' +
            '<li>Detail 2: {point.detail2}</li>' +
            '</ul>' +
            '<div style="text-align: center;">' +
            '<img src="https://picsum.photos/seed/picsum/80/60" alt="Image" style="display: block; margin: 0 auto;" />' +
            '</div>',
        valueDecimals: 2,
        style: {
            pointerEvents: 'auto'
        },
    },
    stockTools: {
        enabled: false,
        gui: {
            buttons: []
        }
    },
    plotOptions: {
        series: {
            pointStart: Date.UTC(2021, 0, 1),
            pointIntervalUnit: 'month',
        }
    },
    series: [{
        name: 'Short',
        data: [
            29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
            95.6, 54.4
        ]
    }, {
        name: 'Long named series',
        data: [
            106.4, 171.5, 129.9, 154.4, 195.6, 194.1, 216.4, 148.5, 135.6,
            176, 144, 129.2
        ]
    }]
};

const RichTooltip = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        Highcharts.AST.allowedAttributes.push('onmousedown');

        if (chartRef.current && chartRef.current.chart) {
            const chart = chartRef.current.chart;

            const handleClick1 = (event) => {
                if (event.target && event.target.matches('.custom-button1')) {
                    alert('Button 1 clicked!');
                }
            };
            const handleClick2 = (event) => {
                if (event.target && event.target.matches('.custom-button2')) {
                    alert('Button 2 clicked!');
                }
            };

            /* const handleMouseDown = (event) => {
                if (event.target && event.target.matches('.tooltip-csq8F5kS')) {
                    // Your onMouseDown logic here
                    event.stopPropagation();
                    console.log('Mouse down on tooltip-csq8F5kS!');
                }
            }; */

            // Add event listener to the entire chart container for better event delegation
            chart.container.addEventListener('click', handleClick1);
            chart.container.addEventListener('click', handleClick2);
            // chart.container.addEventListener('mousedown', handleMouseDown);

            // Cleanup function to remove the event listener
            return () => {
                chart.container?.removeEventListener('click', handleClick1);
                chart.container?.removeEventListener('click', handleClick2);
                // chart.container?.removeEventListener('mousedown', handleMouseDown);
            };
        }
    }, []);

    return (
        <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            constructorType={"chart"}
            options={chartOptions}
            immutable={false}
        />
    );
};

export { RichTooltip };
