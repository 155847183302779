const ColorPicker = ({ colors, selectedColor, onColorSelect }) => (
    <div className="grid grid-cols-6 gap-2 mt-2 mb-4">
        {colors.map((color, index) => (
            <div
                key={index}
                className={`cursor-pointer w-8 h-8 border-2 rounded-full ${selectedColor === color ? 'border-red-500' : 'border-slate-200'}`}
                style={{ backgroundColor: color }}
                onClick={() => onColorSelect(color)}
            />
        ))}
    </div>
);

export { ColorPicker };