import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";
import Accessibility from "highcharts/modules/accessibility.js";
import Exporting from "highcharts/modules/exporting.js";
import ExportingData from "highcharts/modules/export-data.js";
import Hollowcandlestick from "highcharts/modules/hollowcandlestick.js";
import Heikinashi from "highcharts/modules/heikinashi.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Navigator from "highcharts/modules/navigator.js";
import { ChartOptionsData } from "./ChartData";
import { Popup } from "./Popup/Popup";
import {
  DEFAULT_BORDER_WIDTH,
  DEFAULT_FONT_SIZE,
  LABEL,
  PREDEFINED_BACKGROUND_COLORS,
  PREDEFINED_TEXT_COLORS,
} from "./Popup/constants";

// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);
Exporting(Highcharts);
ExportingData(Highcharts);
Drilldown(Highcharts);
Accessibility(Highcharts);
Hollowcandlestick(Highcharts);
Heikinashi(Highcharts);
Navigator(Highcharts);

export function ExportChart() {
  const senderChartRef = useRef(null);
  const receiverChartRef = useRef(null);

  const [savedChartOptionsData, setSavedChartOptionsData] = useState({});
  const [senderChartOptions, setSenderChartOptions] = useState({
    chart: {
      type: "column",
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Curr",
        data: ChartOptionsData.Curr,
        color: "rgba(186,60,61,0.9)",
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
      {
        name: "Exp",
        data: ChartOptionsData.Exp,
        color: "rgba(126,86,134,0.9)",
        pointPadding: 0.3,
        pointPlacement: 0.2,
      },
    ],
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      shared: false,
      valueSuffix: "%",
      xDateFormat: "%b %e, %Y",
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const hours = date.getUTCHours();
          const day = date.getUTCDate();

          if (hours === 0 && day === 1) {
            // Monthly label format
            return Highcharts.dateFormat("%b", this.value);
          } else if (hours === 0) {
            // Day label format
            return Highcharts.dateFormat("%e", this.value);
          } else {
            // Hour label format
            return Highcharts.dateFormat("%H:%M", this.value);
          }
        },
      },
    },
    yAxis: [
      {
        opposite: false,
        //min: 0,
       // max: 120,
        title: {
          text: "Seat factor (%)",
        },
      },
      {
        opposite: true,
      //  min: 0,
       // max: 120,
        title: {
          text: "No show",
        },
      },
    ],
    navigation: {
      bindingsClassName: "tools-container",
      bindings: {
        circleAnnotation: {
          init: function (e) {
            handleActiveShape("circle");
            const chart = this.chart;
            const navigation = chart.navigationBindings;
            const options = this.options.annotations;
            const navigationBindings = this;

            const annotation = chart.addAnnotation(
              Highcharts.merge(options, {
                labels: [
                  {
                    point: {
                      x: e.chartX,
                      y: e.chartY,
                      xAxis: 0,
                      yAxis: 0,
                    },
                    text: "Annotation",
                  },
                ],
              })
            );

            navigation.activeAnnotation = annotation;
            navigationBindings.unbindMouseUp = Highcharts.addEvent(
              document,
              "mouseup",
              function () {
                navigationBindings.unbindMouseUp();
              }
            );

            return annotation;
          },

          end: function (e) {
            handleActiveShape();
            const navigation = this.chart.navigationBindings;
            const annotation = navigation.activeAnnotation;

            if (annotation) {
              annotation.destroy();
              delete navigation.activeAnnotation;
            }

            this.chart.redraw();
          },
        },
        ellipseAnnotation: {
          init: function (e) {
            handleActiveShape("ellipse");
            const chart = this.chart;
            const navigation = chart.navigationBindings;
            const options = this.options.annotations;
            const navigationBindings = this;

            const annotation = chart.addAnnotation(
              Highcharts.merge(options, {
                labels: [
                  {
                    point: {
                      x: e.chartX,
                      y: e.chartY,
                      xAxis: 0,
                      yAxis: 0,
                    },
                    text: "Annotation",
                  },
                ],
              })
            );

            navigation.activeAnnotation = annotation;
            navigationBindings.unbindMouseUp = Highcharts.addEvent(
              document,
              "mouseup",
              function () {
                navigationBindings.unbindMouseUp();
              }
            );

            return annotation;
          },

          end: function (e) {
            handleActiveShape();
            const navigation = this.chart.navigationBindings;
            const annotation = navigation.activeAnnotation;

            if (annotation) {
              annotation.destroy();
              delete navigation.activeAnnotation;
            }

            this.chart.redraw();
          },
        },
        rectangleAnnotation: {
          init: function (e) {
            handleActiveShape("rectangle");
            const chart = this.chart;
            const navigation = chart.navigationBindings;
            const options = this.options.annotations;
            const navigationBindings = this;

            const annotation = chart.addAnnotation(
              Highcharts.merge(options, {
                labels: [
                  {
                    point: {
                      x: e.chartX,
                      y: e.chartY,
                      xAxis: 0,
                      yAxis: 0,
                    },
                    text: "Annotation",
                  },
                ],
              })
            );

            navigation.activeAnnotation = annotation;
            navigationBindings.unbindMouseUp = Highcharts.addEvent(
              document,
              "mouseup",
              function () {
                navigationBindings.unbindMouseUp();
              }
            );

            return annotation;
          },

          end: function (e) {
            handleActiveShape();
            const navigation = this.chart.navigationBindings;
            const annotation = navigation.activeAnnotation;

            if (annotation) {
              annotation.destroy();
              delete navigation.activeAnnotation;
            }

            this.chart.redraw();
          },
        },
        labelAnnotation: {
          init: function (e) {
            setIsInLabelingMode(true);
            const chart = this.chart;
            const navigation = chart.navigationBindings;
            const options = this.options.annotations;
            const navigationBindings = this;

            const annotation = chart.addAnnotation(
              Highcharts.merge(options, {
                labels: [
                  {
                    point: {
                      x: e.chartX,
                      y: e.chartY,
                      xAxis: 0,
                      yAxis: 0,
                    },
                    text: "Annotation",
                  },
                ],
              })
            );

            navigation.activeAnnotation = annotation;
            navigationBindings.unbindMouseUp = Highcharts.addEvent(
              document,
              "mouseup",
              function () {
                navigationBindings.unbindMouseUp();
              }
            );

            return annotation;
          },

          end: function (e) {
            setIsInLabelingMode(false);
            const navigation = this.chart.navigationBindings;
            const annotation = navigation.activeAnnotation;

            if (annotation) {
              annotation.destroy();
              delete navigation.activeAnnotation;
            }

            this.chart.redraw();
          },
        },
      },
      events: {
        showPopup(event) {
          const chart = this.chart;
          if (
            event.formType === "annotation-toolbar" &&
            !this.chart.activeButton
          ) {
            const editButton = document.querySelector(
              ".highcharts-annotation-edit-button"
            );
            if (editButton) {
              Highcharts.addEvent(editButton, "click", function (editEvent) {
                chart.currentAnnotation = event.annotation;
                if (event.annotation.basicType) {
                  setShapeType(event.annotation.basicType);
                } else {
                  setShapeType(() => event.annotation.options.type);
                }
                const shapeTypeLabel =
                  event.annotation.basicType || event.annotation.options.type;
                handleEditClick(shapeTypeLabel);
              });
            }
          } else {
            const defaultPopup = document.querySelector(
              ".highcharts-popup.highcharts-no-tooltip.highcharts-no-mousewheel"
            );

            if (defaultPopup) {
              defaultPopup.style.display = "none";
            }
          }
        },
        selectButton(event) {
          this.chart.activeButton = event.button;
        },
        deselectButton(event) {
          this.chart.currentAnnotation = null;
          this.chart.navigationBindings.deselectAnnotation();
          this.chart.activeButton = null;
        },
      },
    },
    navigator: {
      enabled: true,
    },
  });
  const [receiverChartOptions, setReceiverChartOptions] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [labelText, setLabelText] = useState("");
  const [selectedColor, setSelectedColor] = useState(
    PREDEFINED_BACKGROUND_COLORS[0]
  );
  const [selectedFontSize, setSelectedFontSize] = useState(DEFAULT_FONT_SIZE);
  const [shapeType, setShapeType] = useState("");
  const [strokeColor, setStrokeColor] = useState(PREDEFINED_TEXT_COLORS[1]);
  const [strokeWidth, setStrokeWidth] = useState(DEFAULT_BORDER_WIDTH);

  const [showShapesMenu, setShowShapesMenu] = useState(false);
  const [isShapeDrawingMode, setIsShapeDrawingMode] = useState(false);
  const [isInLabelingMode, setIsInLabelingMode] = useState(false);
  const [multiShapeIconBgImage, setMultiShapeIconBgImage] = useState(
    `url('/multi-shape.png')`
  );

  useEffect(() => {
    const { chart } = senderChartRef.current;
    if (senderChartRef.current && chart.currentAnnotation) {
      if (shapeType === LABEL) {
        chart.currentAnnotation.update({
          labels: [
            {
              format: labelText,
              backgroundColor: selectedColor,
              style: {
                color: strokeColor,
                fontSize: `${selectedFontSize}`,
              },
            },
          ],
        });
      } else {
        chart.currentAnnotation.update({
          shapes: [
            {
              fill: selectedColor,
              stroke: strokeColor,
              strokeWidth: strokeWidth,
            },
          ],
        });
      }
    }
  }, [
    shapeType,
    labelText,
    selectedFontSize,
    strokeColor,
    strokeWidth,
    selectedColor,
  ]);

  const handleExportChart = () => {
    if (senderChartRef.current) {
      const updatedOptions = senderChartRef.current.chart.options;
      setSavedChartOptionsData(updatedOptions);
      console.log({ updatedOptions });

      const dataJSON =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(updatedOptions));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataJSON);
      downloadAnchorNode.setAttribute(
        "download",
        "custom-toolbar-chart-with-export-capabilities.json"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSenderChartOptions(file);
    if (file && file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const chartOptionsData = JSON.parse(e.target.result);
        setReceiverChartOptions(chartOptionsData);
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid JSON file.");
    }
  };

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    const { chart } = senderChartRef.current;
    setIsPopupVisible(false);
    chart.currentAnnotation = null;
    chart.navigationBindings.deselectAnnotation();
  };

  const handleStrokeWidthChange = (event) => {
    setStrokeWidth(event.target.value);
  };

  const handleLabelTextChange = (event) => {
    setLabelText(event.target.value);
  };

  const handleFontSizeChange = (event) => {
    setSelectedFontSize(event.target.value);
  };

  const handleEditClick = (shapeTypeLabel) => {
    const chart = senderChartRef.current.chart;
    const existingPopup = chart.container.querySelector(".custom-popup");
    if (existingPopup) {
      existingPopup.remove();
    }

    const defaultPopup = document.querySelector(
      ".highcharts-popup.highcharts-no-tooltip.highcharts-no-mousewheel"
    );
    if (defaultPopup) {
      if (!defaultPopup.classList.contains("highcharts-annotation-toolbar")) {
        defaultPopup.style.display = "none";

        while (defaultPopup.firstChild) {
          defaultPopup.removeChild(defaultPopup.firstChild);
        }
      }
    }

    if (shapeTypeLabel === LABEL) {
      const currentLabel = chart.currentAnnotation.labels[0];
      const label =
        currentLabel.options.format === "{y:.2f}"
          ? currentLabel.points[0].y.toFixed(2)
          : currentLabel.options.format;

      const fontColor =
        currentLabel.options.style.color === "#FFFFFF"
          ? "rgb(255, 255, 255)"
          : currentLabel.options.style.color;

      const fontSize =
        currentLabel.options.style.fontSize === "0.7em"
          ? "12"
          : currentLabel.options.style.fontSize;
      setLabelText(label);
      setSelectedFontSize(fontSize);
      setStrokeColor(fontColor);
      setSelectedColor(currentLabel.options.backgroundColor);
    } else {
      const currentShape = chart.currentAnnotation.shapes[0].options;
      const borderColor =
        currentShape.stroke === "rgba(0, 0, 0, 0.75)"
          ? "rgb(0, 0, 0)"
          : currentShape.stroke;
      setStrokeColor(borderColor);
      setStrokeWidth(currentShape.strokeWidth);
      setSelectedColor(currentShape.fill);
    }

    showPopup();
  };

  function handleActiveShape(shape) {
    if (shape === "circle") {
      setIsShapeDrawingMode(true);
      setShowShapesMenu(false);
      setMultiShapeIconBgImage(
        `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/circle.svg")`
      );
      return;
    } else if (shape === "ellipse") {
      setIsShapeDrawingMode(true);
      setShowShapesMenu(false);
      setMultiShapeIconBgImage(
        `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/ellipse.svg")`
      );
      return;
    } else if (shape === "rectangle") {
      setIsShapeDrawingMode(true);
      setShowShapesMenu(false);
      setMultiShapeIconBgImage(
        `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/rectangle.svg")`
      );
      return;
    }

    setIsShapeDrawingMode(false);

    setMultiShapeIconBgImage(`url('/multi-shape.png')`);
  }

  return (
    <div>
      <h2 className="my-4">Custom Toolbar Chart with Export Capabilities</h2>
      <div className="border-2 w-full h-[560px] text-right p-4">
        <button
          className="bg-blue-500 text-white mb-3 px-4 py-2 rounded-md"
          onClick={handleExportChart}
        >
          Export
        </button>
        <div className="flex">
          <div className="tools-container px-4 relative flex flex-col gap-2">
            <button
              className=" h-10 w-10"
              style={{
                backgroundImage: multiShapeIconBgImage,
                backgroundSize: "cover",
                backgroundColor: isShapeDrawingMode ? "orange" : "#f7f7f7",
              }}
              onClick={() => setShowShapesMenu(!showShapesMenu)}
            />

            {showShapesMenu && (
              <div className="absolute top -right-8 z-50 flex flex-col gap-2">
                <button
                  className="highcharts-circle-annotation"
                  style={{
                    backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/circle.svg")`,
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#f7f7f7",
                  }}
                />{" "}
                <button
                  className="highcharts-ellipse-annotation"
                  style={{
                    backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/ellipse.svg")`,
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#f7f7f7",
                  }}
                />{" "}
                <button
                  className="highcharts-rectangle-annotation"
                  style={{
                    backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/rectangle.svg")`,
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#f7f7f7",
                  }}
                />
              </div>
            )}
            <button
              className="highcharts-label-annotation"
              style={{
                backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/label.svg")`,
                height: "40px",
                width: "40px",
                backgroundColor: isInLabelingMode ? "orange" : "#f7f7f7",
              }}
            />
          </div>
          <div className="w-full">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"chart"}
              options={senderChartOptions}
              immutable={false}
              ref={senderChartRef}
            />
          </div>
        </div>
      </div>
      <h2 className="mt-16 mb-4">Shared Chart</h2>
      <div className="border-2 w-full h-[560px] p-4">
        <label
          htmlFor="uploadFile"
          className="flex bg-blue-500 text-white text-base px-5 py-3 outline-none rounded mb-4 w-max cursor-pointer"
        >
          {senderChartOptions && senderChartOptions.name ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              className="w-6 mr-2"
            >
              <path
                fill="#c8e6c9"
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              />
              <path
                fill="#4caf50"
                d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 mr-2 fill-white inline"
              viewBox="0 0 32 32"
            >
              <path
                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                data-original="#000000"
              />
              <path
                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                data-original="#000000"
              />
            </svg>
          )}
          {senderChartOptions && senderChartOptions.name
            ? senderChartOptions.name
            : "Upload"}
          <input
            className="hidden"
            type="file"
            accept="application/json"
            onChange={handleFileUpload}
            id="uploadFile"
          />
        </label>
        {receiverChartOptions && (
          <div className="flex">
            <div className="tools-container px-4 relative flex flex-col gap-2">
              <button
                className=" h-10 w-10"
                style={{
                  backgroundImage: multiShapeIconBgImage,
                  backgroundSize: "cover",
                  backgroundColor: isShapeDrawingMode ? "orange" : "#f7f7f7",
                }}
                onClick={() => setShowShapesMenu(!showShapesMenu)}
              />

              {showShapesMenu && (
                <div className="absolute top -right-8 z-50 flex flex-col gap-2">
                  <button
                    className="highcharts-circle-annotation"
                    style={{
                      backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/circle.svg")`,
                      height: "40px",
                      width: "40px",
                      backgroundColor: "#f7f7f7",
                    }}
                  />{" "}
                  <button
                    className="highcharts-ellipse-annotation"
                    style={{
                      backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/ellipse.svg")`,
                      height: "40px",
                      width: "40px",
                      backgroundColor: "#f7f7f7",
                    }}
                  />{" "}
                  <button
                    className="highcharts-rectangle-annotation"
                    style={{
                      backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/rectangle.svg")`,
                      height: "40px",
                      width: "40px",
                      backgroundColor: "#f7f7f7",
                    }}
                  />
                </div>
              )}
              <button
                className="highcharts-label-annotation"
                style={{
                  backgroundImage: `url("https://code.highcharts.com/11.4.5/gfx/stock-icons/label.svg")`,
                  height: "40px",
                  width: "40px",
                  backgroundColor: isInLabelingMode ? "orange" : "#f7f7f7",
                }}
              />
            </div>
            <div className="w-full">
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"chart"}
                options={receiverChartOptions}
                immutable={false}
                ref={receiverChartRef}
              />
            </div>
          </div>
        )}
      </div>
      {isPopupVisible &&
        senderChartRef.current &&
        createPortal(
          <Popup
            onClose={hidePopup}
            labelText={labelText}
            handleLabelTextChange={handleLabelTextChange}
            selectedFontSize={selectedFontSize}
            handleFontSizeChange={handleFontSizeChange}
            setSelectedColor={setSelectedColor}
            handleStrokeWidthChange={handleStrokeWidthChange}
            setStrokeColor={setStrokeColor}
            shapeType={shapeType}
            strokeWidth={strokeWidth}
            selectedColor={selectedColor}
            strokeColor={strokeColor}
          />,
          senderChartRef.current.chart.container
        )}
    </div>
  );
}
