export const PREDEFINED_BACKGROUND_COLORS = [
    'rgba(0, 0, 0, 0.75)', 'rgba(0, 0, 0, 0)', 'rgba(255, 205, 210, 0.4)',
    'rgba(248, 187, 208, 0.4)', 'rgba(225, 190, 231, 0.4)', 'rgba(209, 196, 233, 0.4)',
];

export const PREDEFINED_TEXT_COLORS = [
    'rgb(0, 0, 0)', 'rgb(255, 255, 255)', 'rgb(211, 47, 47)',
    'rgb(194, 24, 91)', 'rgb(123, 31, 162)', 'rgb(81, 45, 168)',
];

export const LABEL = "label";

export const DEFAULT_FONT_SIZE = "12px";

export const DEFAULT_BORDER_WIDTH = 1;