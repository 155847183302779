import { ColorPicker } from "../ColorPicker/ColorPicker";
import { PREDEFINED_TEXT_COLORS, PREDEFINED_BACKGROUND_COLORS } from "../constants";

const ShapesOptions = ({
    shapeType, selectedColor, strokeColor, strokeWidth,
    setSelectedColor, setStrokeColor, handleStrokeWidthChange, onClose
}) => (
    <>
        <div className="flex justify-between items-center border-b-2 border-slate-200 py-2">
            <h2 className="capitalize">{shapeType}</h2>
            <button className="font-bold text-xl" onClick={onClose}>X</button>
        </div>
        <div className="overflow-y-auto max-h-60 px-2">
            <h3 className="mt-2">Border color</h3>
            <ColorPicker colors={PREDEFINED_TEXT_COLORS} selectedColor={strokeColor} onColorSelect={setStrokeColor} />
            <h3 className="my-2">Border width</h3>
            <input
                type="number"
                value={strokeWidth}
                onChange={handleStrokeWidthChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <h3 className="mt-2">Shape fill</h3>
            <ColorPicker colors={PREDEFINED_BACKGROUND_COLORS} selectedColor={selectedColor} onColorSelect={setSelectedColor} />
        </div>
    </>
);

export { ShapesOptions };