import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
import React from 'react';

// Initialize the Highcharts drilldown module
drilldown(Highcharts);

let isSyncing = false;

function syncCharts(e, chart) {
  if (isSyncing) {
    return;
  }

  isSyncing = true;

  if (e.type === 'drilldown') {
    Highcharts.charts.forEach(c => {
      if (c !== chart) {
        var series = c?.series[0],
          point = series?.points[e.point.index];

        point?.doDrilldown();
      }
    });
  } else {
    Highcharts.charts.forEach(c => {
      if (c !== chart) {
        c?.drillUp();
      }
    });
  }
  isSyncing = false;
}

let isMouseMoving = false;

function syncTooltip(e) {
  if (isMouseMoving) return;

  let chart, point, i, event;

  for (i = 0; i < Highcharts.charts.length; i = i + 1) {
    chart = Highcharts.charts[i];
    // Find coordinates within the chart
    event = chart?.pointer.normalize(e);
    // Get the hovered point
    point = chart?.series[0].searchPoint(event, true);

    if (point) {
      isMouseMoving = true;
      Highcharts.charts.forEach(function (c) {
        var hoverPoint = c?.series[0]?.points[point.index];
        if (hoverPoint) {
          hoverPoint.onMouseOver();
        }
      });
      isMouseMoving = false;
      break;
    }
  }
}

// Chart options
const columnChartOptions = {
  chart: {
    type: 'column',
    events: {
      drilldown(e) {
        syncCharts(e, this);
      },
      drillup(e) {
        syncCharts(e, this);
      },
      mouseOver: syncTooltip,
    }
  },
  title: {
    text: 'Car Sales'
  },
  xAxis: {
    type: 'category'
  },
  yAxis: {
    title: {
      text: 'Total percent Car Sales'
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%'
      },
      events: {
        mouseOver: syncTooltip,
      }
    }
  },
  tooltip: {
    shared: true,
    valueDecimals: 1,
  },
  series: [{
    name: 'Years',
    colorByPoint: true,
    data: [
      { name: '2015', y: 62.74, drilldown: '2015' },
      { name: '2016', y: 10.57, drilldown: '2016' },
      { name: '2017', y: 7.23, drilldown: '2017' },
      { name: '2018', y: 5.58, drilldown: '2018' },
    ]
  }],
  drilldown: {
    series: [
      {
        name: '2015',
        id: '2015',
        data: [
          { name: 'Q1', y: 0.4 }, { name: 'Q2', y: 0.3 }, { name: 'Q3', y: 2 }, { name: 'Q4', y: 0.1 }
        ]
      },
      {
        name: '2016',
        id: '2016',
        data: [
          { name: 'Q1', y: 0.1 }, { name: 'Q2', y: 0.2 }, { name: 'Q3', y: 0.1 }, { name: 'Q4', y: 0.1 }
        ]
      },
      {
        name: '2017',
        id: '2017',
        data: [
          { name: 'Q1', y: 0.1 }, { name: 'Q2', y: 0.3 }, { name: 'Q3', y: 0.1 }, { name: 'Q4', y: 0.1 }
        ]
      },
      {
        name: '2018',
        id: '2018',
        data: [
          { name: 'Q1', y: 0.1 }, { name: 'Q2', y: 0.1 }, { name: 'Q3', y: 0.3 }
        ]
      }
    ]
  }
};

const pieChartOptions = {
  chart: {
    type: 'pie',
    events: {
      drilldown(e) {
        syncCharts(e, this);
      },
      drillup(e) {
        syncCharts(e, this);
      },
      mouseOver: syncTooltip,
    }
  },
  title: {
    text: 'Car Sales by Region'
  },
  xAxis: {
    type: 'category'
  },
  yAxis: {
    title: {
      text: 'Total percent Car Sales'
    }
  },
  legend: {
    enabled: true
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%'
      },
      events: {
        mouseOver: syncTooltip,
      }
    }
  },
  tooltip: {
    shared: true,
    valueDecimals: 1,
  },
  series: [{
    name: 'Regions',
    colorByPoint: true,
    data: [
      { name: 'Asia', y: 62.74, drilldown: 'Asia' },
      { name: 'Europe', y: 10.57, drilldown: 'Europe' },
      { name: 'America', y: 7.23, drilldown: 'America' },
      { name: 'Australia', y: 5.58, drilldown: 'Australia' }
    ]
  }],
  drilldown: {
    series: [
      {
        name: 'Asia',
        id: 'Asia',
        data: [
          { name: 'India', y: 0.1 }, { name: 'Sri Lanka', y: 0.2 }, { name: 'Japan', y: 0.3 }, { name: 'Singapore', y: 0.4 }
        ]
      },
      {
        name: 'Europe',
        id: 'Europe',
        data: [
          { name: 'UK', y: 0.1 }, { name: 'Russia', y: 0.2 }, { name: 'France', y: 0.3 }, { name: 'Germany', y: 0.4 }
        ]
      },
      {
        name: 'America',
        id: 'America',
        data: [
          { name: 'North America', y: 0.3 }, { name: 'South America', y: 0.4 }
        ]
      },
      {
        name: 'Australia',
        id: 'Australia',
        data: [
          { name: 'New Zealand', y: 0.1 }, { name: 'Australia', y: 0.5 }
        ]
      }
    ]
  }
};

const lineChartOptions = {
  chart: {
    type: 'line',
    events: {
      drilldown(e) {
        syncCharts(e, this);
      },
      drillup(e) {
        syncCharts(e, this);
      },
      mouseOver: syncTooltip,
    }
  },
  title: {
    text: 'Car Sales Historical Data'
  },
  xAxis: {
    type: 'category',
    title: {
      text: 'Years'
    }
  },
  yAxis: {
    title: {
      text: 'Total percent Car Sales'
    }
  },
  legend: {
    enabled: true
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%'
      },
      events: {
        mouseOver: syncTooltip,
      }
    }
  },
  tooltip: {
    shared: true,
    valueDecimals: 1,
  },
  series: [{
    name: 'Years',
    colorByPoint: true,
    data: [
      { name: '2015', y: 62.74, drilldown: '2015' },
      { name: '2016', y: 10.57, drilldown: '2016' },
      { name: '2017', y: 7.23, drilldown: '2017' },
      { name: '2018', y: 5.58, drilldown: '2018' },
    ]
  }],
  drilldown: {
    series: [
      {
        name: '2015',
        id: '2015',
        data: [
          { name: 'Q1', y: 0.4, drilldown: '2015-Q1' },
          { name: 'Q2', y: 0.3, drilldown: '2015-Q2' },
          { name: 'Q3', y: 2.0, drilldown: '2015-Q3' },
          { name: 'Q4', y: 0.1, drilldown: '2015-Q4' }
        ]
      },
      {
        name: '2016',
        id: '2016',
        data: [
          { name: 'Q1', y: 0.1, drilldown: '2016-Q1' },
          { name: 'Q2', y: 0.2, drilldown: '2016-Q2' },
          { name: 'Q3', y: 0.1, drilldown: '2016-Q3' },
          { name: 'Q4', y: 0.1, drilldown: '2016-Q4' }
        ]
      },
      {
        name: '2017',
        id: '2017',
        data: [
          { name: 'Q1', y: 0.1, drilldown: '2017-Q1' },
          { name: 'Q2', y: 0.3, drilldown: '2017-Q2' },
          { name: 'Q3', y: 0.1, drilldown: '2017-Q3' },
          { name: 'Q4', y: 0.1, drilldown: '2017-Q4' }
        ]
      },
      {
        name: '2018',
        id: '2018',
        data: [
          { name: 'Q1', y: 0.1, drilldown: '2018-Q1' },
          { name: 'Q2', y: 0.1, drilldown: '2018-Q2' },
          { name: 'Q3', y: 0.3, drilldown: '2018-Q3' }
        ]
      }
    ]
  }
};

const SyncWithDrillDown = () => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnChartOptions}
        />
      </div>
      <div className="flex flex-row items-center justify-around">
        <HighchartsReact
          highcharts={Highcharts}
          options={pieChartOptions}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={lineChartOptions}
        />
      </div>
    </div>
  );
}

export { SyncWithDrillDown };