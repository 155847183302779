import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import DraggablePoints from 'highcharts/modules/draggable-points';
import Navigator from 'highcharts/modules/navigator';
import { useEffect } from 'react';

// Import highcharts modules
DraggablePoints(Highcharts);
Navigator(Highcharts);


const LINE_CHART_DATA_ORIGINAL = [
    [
        1722470400000,
        225
    ],
    [
        1722556800000,
        389
    ],
    [
        1722643200000,
        287
    ],
    [
        1722729600000,
        128
    ],
    [
        1722816000000,
        178
    ],
    [
        1722902400000,
        227
    ],
    [
        1722988800000,
        119
    ],
    [
        1723075200000,
        140
    ],
    [
        1723161600000,
        174
    ]
];

let LINE_CHART_DATA = [
    [
        1722470400000,
        225
    ],
    [
        1722556800000,
        389
    ],
    [
        1722643200000,
        287
    ],
    [
        1722729600000,
        128
    ],
    [
        1722816000000,
        178
    ],
    [
        1722902400000,
        227
    ],
    [
        1722988800000,
        119
    ],
    [
        1723075200000,
        140
    ],
    [
        1723161600000,
        174
    ]
];

//Chart options

const COLUMN_CHART_DATA_ORIGINAL = {
    "profit": [
        [
            1722470400000,
            3109
        ],
        [
            1722556800000,
            5003
        ],
        [
            1722643200000,
            9311
        ],
        [
            1722729600000,
            15401
        ],
        [
            1722816000000,
            3033
        ],
        [
            1722902400000,
            1287
        ],
        [
            1722988800000,
            13927
        ],
        [
            1723075200000,
            33927
        ],
        [
            1723161600000,
            907
        ]
    ],
    "revenue": [
        [
            1722470400000,
            16690
        ],
        [
            1722556800000,
            18033
        ],
        [
            1722643200000,
            18908
        ],
        [
            1722729600000,
            5143
        ],
        [
            1722816000000,
            20045
        ],
        [
            1722902400000,
            17161
        ],
        [
            1722988800000,
            34563
        ],
        [
            1723075200000,
            33331
        ],
        [
            1723161600000,
            10536
        ]
    ]
};

let COLUMN_CHART_DATA = {
    "profit": [
        [
            1722470400000,
            3109
        ],
        [
            1722556800000,
            5003
        ],
        [
            1722643200000,
            9311
        ],
        [
            1722729600000,
            15401
        ],
        [
            1722816000000,
            3033
        ],
        [
            1722902400000,
            1287
        ],
        [
            1722988800000,
            13927
        ],
        [
            1723075200000,
            33927
        ],
        [
            1723161600000,
            907
        ]
    ],
    "revenue": [
        [
            1722470400000,
            16690
        ],
        [
            1722556800000,
            18033
        ],
        [
            1722643200000,
            18908
        ],
        [
            1722729600000,
            5143
        ],
        [
            1722816000000,
            20045
        ],
        [
            1722902400000,
            17161
        ],
        [
            1722988800000,
            34563
        ],
        [
            1723075200000,
            33331
        ],
        [
            1723161600000,
            10536
        ]
    ]
}

// Function to transform data into cumulative values
const transformToCumulative = (data) => {
    let cumulativeTotal = 0;
    return data.map(([timestamp, value]) => {
      cumulativeTotal += value;
      return [timestamp, cumulativeTotal];
    });
};

// Transform the original revenue data into cumulative revenue data
let CUMULATIVE_REVENUE = transformToCumulative(COLUMN_CHART_DATA.revenue);

// Transform the original profit data into cumulative profit data
let CUMULATIVE_PROFIT = transformToCumulative(COLUMN_CHART_DATA.profit);

const lineChartOptions = {
    chart: {
        type: 'spline',
        zoomType: 'x',
        zooming: {
            mouseWheel: {
                enabled:false
            }
        }
    },
    title: {
        text: 'Daily Basic Fare'
    },
    xAxis: {
        type: 'datetime',
        title: {
            text: 'Date'
        },
        labels: {
            format: '{value:%e %b}'
        },
        crosshair: true,
        events: {
            setExtremes: syncExtremes
        },
    },
    yAxis: [{
        title: {
            text: 'Basic Fare ($)'
        },
        min: 100,
        max: 500,
    },
    {
        title: {
            text: 'Basic Fare ($)'
        },
        min: 100,
        max: 500,
        opposite: true
    }],
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '${point.y:.0f}'
            },
            stickyTracking: false,
            dragDrop: {
                draggableX: false,
                draggableY: true,
            },
            line: {
                cursor: "no-resize"
            }
        }
    },
    tooltip: {
        shared: false,
        valueDecimals: 0,
        valuePrefix: '$',
    },
    rangeSelector: {
        enabled: true,
        selected: 0,
        buttons: [{
            type: 'month',
            count: 1,
            text: '1m',
        }, {
            type: 'month',
            count: 3,
            text: '3m',
        }, {
            type: 'all',
            text: 'All',
        }],

    },
    stockTools: {
        gui: {
            enabled: false
        }
    },
    navigator: {
        enabled: true
    },
    series: [{
        name: 'Basic Fare',
        colorByPoint: true,
        data: LINE_CHART_DATA,
        point: {
            events: {
                drop: function (event) {
                    syncColumnCharts(this);
                },
            }
        },
    }],
};

const columnChartOptions = {
    chart: {
        type: 'column',
        zoomType: 'x',
        zooming: {
            mouseWheel: {
                enabled:false
            }
        }
    },
    title: {
        text: 'Revenue and Profit'
    },
    xAxis: {
        type: 'datetime',
        title: {
            text: 'Date'
        },
        labels: {
            format: '{value:%e %b}',
        },
    },
    yAxis: [{
        min: 0,
        title: {
            text: 'Profit ($)'
        },
        labels: {
            formatter: function () {
                return '$' + this.value / 1000 + 'K';
            }
        }
    }, {
        title: {
            text: 'Revenue ($)'
        },
        labels: {
            formatter: function () {
                return '$' + this.value / 1000 + 'K';
            }
        },
        opposite: true
    }],
    rangeSelector: {
        enabled: false,
        selected: 0,
        buttons: [{
            type: 'month',
            count: 1,
            text: '1m',
        }, {
            type: 'month',
            count: 3,
            text: '3m'
        }, {
            type: 'all',
            text: 'All'
        }]
    },
    legend: {
        shadow: false
    },
    tooltip: {
        shared: false,
        valueDecimals: 0,
        valuePrefix: '$',
        formatter: function () {
            let series = this.point.series.chart.series,
                index = this.point.series.points.indexOf(this.point),
                str = '';

                let date = Highcharts.dateFormat('%A, %b %e, %Y', this.x); 

                str += date + '<br/>';

            for (let i = 0; i < series.length; i++) {
                let color = series[i].color; // Get the color of the series
                let value = Math.round(series[i].points[index].y); 
                str += '<span style="color:' + color + '">●</span> ' + series[i].name + ': <strong>$' + value + '</strong><br/>';
            }
            return str;
        }
    },
    plotOptions: {
        column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
        }
    },
    stockTools: {
        gui: {
            enabled: false
        }
    },
    navigator: {
        enabled: false
    },
    series: [ {
        name: 'Profit',
        color: 'rgba(126,86,134,.9)',
        data: CUMULATIVE_PROFIT,
        tooltip: {
            valuePrefix: '$',
            valueSuffix: ''
        },
        pointPadding: 0.4,
        pointPlacement: -0.2
    }, {
        name: 'Revenue',
        color: 'rgba(186,60,61,.9)',
        data: CUMULATIVE_REVENUE,
        tooltip: {
            valuePrefix: '$',
            valueSuffix: ''
        },
        pointPadding: 0.4,
        pointPlacement: 0.2,
        yAxis: 1
    }]
};

// Sync functions
function syncColumnCharts(chart) {
    const xValue = chart.options.x;
    const newPrice = chart.options.y;

    const linePointIndex = LINE_CHART_DATA_ORIGINAL.findIndex(point => point[0] === xValue);

    const columnChart = Highcharts.charts.find(chart => chart && chart.renderTo.id === 'column-12345');

    if (columnChart) {
        const columnPointIndex = COLUMN_CHART_DATA_ORIGINAL.profit.findIndex(point => point[0] === xValue);

        if (columnPointIndex !== -1) {
            const actualPrice = LINE_CHART_DATA_ORIGINAL[linePointIndex][1];
            const percentageChange = ((newPrice - actualPrice) / actualPrice) * 100;

            let cumulativeProfitTotal = columnPointIndex > 0 ? columnChart.series[0].data[columnPointIndex - 1].y : 0;
            let cumulativeRevenueTotal = columnPointIndex > 0 ? columnChart.series[1].data[columnPointIndex - 1].y : 0;

            // Update the cumulative profit and revenue for this and subsequent points
            for (let i = columnPointIndex; i < COLUMN_CHART_DATA_ORIGINAL.profit.length; i++) {
                const profit = COLUMN_CHART_DATA_ORIGINAL.profit[i][1];
                const revenue = COLUMN_CHART_DATA_ORIGINAL.revenue[i][1];

                cumulativeProfitTotal += profit * (1 + percentageChange / 100);
                cumulativeRevenueTotal += revenue * (1 + percentageChange / 100);

                columnChart.series[0].data[i].update(cumulativeProfitTotal);
                columnChart.series[1].data[i].update(cumulativeRevenueTotal);
            }
        }
    }
}

/**
 * Synchronize zooming through the setExtremes event handler.
 */
function syncExtremes(e) {
    const thisChart = this.chart;

    if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function (chart) {
            if (chart !== thisChart) {
                if (chart?.xAxis[0]?.setExtremes) { // It is null while updating
                    chart.xAxis[0].setExtremes(
                        e.min,
                        e.max,
                        undefined,
                        false, {
                          trigger: 'syncExtremes'
                        }
                    );
                     // Keep focused if range selector clicked
                     const rangeMap = {
                        '1m': 0,
                        '3m': 1,
                        'All': 2,
                    };
                
                    const buttonIndex = rangeMap[e.rangeSelectorButton?.text];
                    if (buttonIndex !== undefined) {
                        chart.rangeSelector?.clickButton(buttonIndex, null, true);
                    }
                }
            }
        });
    }
}

Highcharts.Pointer.prototype.reset = function () {
    return null;
};

Highcharts.Point.prototype.highlight = function (event) {
    this.series.chart.tooltip.refresh(this); // Show the tooltip
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
};

const DraggableCurve = () => {

    useEffect(() => {
        const handleEvent = (e) => {
            let chart, point, i, event;

            for (i = 0; i < Highcharts.charts.length; i = i + 1) {
                chart = Highcharts.charts[i];
                if (chart) {
                    // Find coordinates within the chart
                    event = chart.pointer.normalize(e);
                    // Get the hovered point
                    point = chart.series[0].searchPoint(event, true);

                    if (point) {
                        point.highlight(e);
                    }
                }
            }
        };

        const events = ["mousemove", "touchmove", "touchstart"];
        const element = document.getElementById("draggable-curve");

        if (element) {
            events.forEach((eventType) => {
                element.addEventListener(eventType, handleEvent);
            });
        }

        // Cleanup function to remove event listeners
        return () => {
            if (element) {
                events.forEach((eventType) => {
                    element.removeEventListener(eventType, handleEvent);
                });
            }
        };
    }, [])

    const resetChart = () => {
        const columnChart = Highcharts.charts.find(chart => chart && chart.renderTo.id === 'column-12345');
        const lineChart = Highcharts.charts.find(chart => chart && chart.renderTo.id === 'line-12345');
        if (columnChart && lineChart) {
            LINE_CHART_DATA = LINE_CHART_DATA_ORIGINAL;

            lineChartOptions.series[0].data = LINE_CHART_DATA;

            // Recompute the cumulative data based on the original column chart data
            const cumulativeProfit = transformToCumulative(COLUMN_CHART_DATA_ORIGINAL.profit);
            const cumulativeRevenue = transformToCumulative(COLUMN_CHART_DATA_ORIGINAL.revenue);

            columnChartOptions.series[0].data = cumulativeProfit;
            columnChartOptions.series[1].data = cumulativeRevenue;

            columnChart.update(columnChartOptions, true);
            lineChart.update(lineChartOptions, true);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <button className="border-solid border-blue-700 border-2 rounded text-blue-700 w-28 self-end" onClick={resetChart}>Reset Chart</button>
            <div id="draggable-curve">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={lineChartOptions}
                    containerProps={{ id: 'line-12345' }}
                />
                <HighchartsReact
                    highcharts={Highcharts}
                    options={columnChartOptions}
                    containerProps={{ id: 'column-12345' }}
                />
            </div>
        </div>
    );
}

export { DraggableCurve };