import { BarChart } from "../BarChart/BarChart";
import { TimeSlider } from "../TimeSlider/TimeSlider";
import { SyncWithDrillDown } from "../SyncWithDrillDown/SyncWithDrillDown";
import { SwitchingCharts } from "../SwitchingCharts/SwitchingCharts";
import { CustomizableComponent } from "../CustomizableComponent/CustomizableComponent";
import { RichTooltip } from "../RichTooltip/RichTooltip";
import { MultiAxis } from "../MultiAxis/MultiAxis";
import { Annotation } from "../Annotation/Annotation";
import { LargeVolumeDataPointChart } from "../LargeVolumeDataPointChart/LargeVolumeDataPointChart";

const ChartsDisplayLayout = () => {
  return (
    <div className="container mx-auto">
      <div className="flex mt-5">
        <a
          className="hover:underline text-blue-400 pointer"
          rel="noreferrer"
          href="https://amcharts.codewalnut.com"
          target="_blank"
        >
          amCharts
        </a>
      </div>
      <h1 className="text-center">Highcharts capability</h1>
      <h2 className="mt-10 mb-2">Combined Bar and Line charts.</h2>
      <p className="mb-4">
        Ability to render lines on top of bars to show dimensions like
        averages, trends or other independent measures from the data source
      </p>
      <MultiAxis />
      <h2 className="mt-10 mb-2">Multi-Axis</h2>
      <p className="mb-4">
        Most solutions show 2, but we have examples with 3 axis.
      </p>
      <MultiAxis />
      <h2 className="mt-10 mb-4">Zoom In/Out</h2>
      <BarChart
        allowMouseWheelZoom={true}
        stockToolsButtons={[]}
        enabledStockTools={true}
      />
      <h2 className="mt-10 mb-2">
      <a
          className="hover:underline text-blue-400 pointer"
          rel="noreferrer"
          href="https://highcharts.codewalnut.com/sabre-poc"
          target="_blank"
        >
           Cross Filter
        </a>
        </h2>
      <p className="mb-4">
        Ability to have selections made on one chart, be automatically reflected
        on other charts in the same page. Usually right below the main chart.
      </p>
      <SyncWithDrillDown />
      <h2 className="mt-10 mb-2">Brush Selection</h2>
      <p className="mb-4">
        Ability to make selections on the chart with an interactive element on
        the chart itself.
      </p>
      <BarChart stockToolsButtons={["zoomChange"]} enabledStockTools={true} />
      <h2 className="mt-10 mb-2">Mini Map</h2>
      <p className="mb-4">
        This is a separate visualization usually right below the chart the shows
        the entire data set zoomed out. Interacting with it allows to pan/move
        to specific sections of the data on the main chart. Need to be able to
        customize the location and size of the mini map based on Spark
        guidelines.
      </p>
      <BarChart isNavigatorEnabled={true} />
      <h2 className="mt-10 mb-2">Custom Notes and Annotations</h2>
      <p className="mb-4">
        Ability to interactively (by user input) add notes/annotations to data
        points on the chart. The notes can be simple text when it's user
        entered. When it's information coming from the data set, it can be HTML
        with more rich notes.
      </p>
      <Annotation />
      <h2 className="mt-10 mb-2">
      <a
          className="hover:underline text-blue-400 pointer"
          rel="noreferrer"
          href="https://highcharts.codewalnut.com/sabre-poc"
          target="_blank"
        >
           Drawing on chart
        </a>
      </h2>
      <p className="mb-4">
        Ability to draw a line/circle on a section of a chart to highlight a
        particular event. This is for potentially saving the chart to share with
        someone within the application itself.
      </p>
      <h2 className="mt-10 mb-2">Custom Tooltips</h2>
      <p className="mb-2">
        Tooltips show on hover and can also be more than just text, but rather
        rich HTML content like lists, images/icons, etc.
      </p>
      <RichTooltip />
      <h2 className="mt-10 mb-2">
        Full visual customization (colors, rounded borders, lines, etc)
      </h2>
      <p className="mb-4">
        We have an Enterprise Design System that sets colors and shapes for our
        visualiations. We need to be able to fully customize the look and feel
        of the charts.
      </p>
      <CustomizableComponent />
      <h2 className="mt-10 mb-2">Drill Down</h2>
      <p className="mb-4"></p>
      <BarChart />
      <h2 className="mt-10 mb-2">Live switching of graph type</h2>
      <p className="mb-4">
        Ability to switch chart types directly on chart, using the same data
        source (visualize same data in different ways).
      </p>
      <SwitchingCharts />
      <h2 className="mt-10 mb-2">Reference Lines</h2>
      <p className="mb-4">
        Render threshold and references line at arbitrary points based on data
        type (top/bottom/middle points, etc.).
      </p>
      <BarChart
        stockToolsButtons={["lines", "zoomChange", "fullScreen"]}
        enabledStockTools={true}
      />
      <h2 className="mt-10 mb-2 text-yellow-500">
      <a
          className="hover:underline text-blue-400 pointer"
          rel="noreferrer"
          href="https://highcharts.codewalnut.com/sabre-poc"
          target="_blank"
        >
           Draggable Curves
        </a>
      </h2>
      <h2 className="mt-10 mb-2">Time Slider</h2>
      <p className="mb-4">
        Ability to render chart values on a timed interval or as user moves
        across X axis, so they can see value move over time.
      </p>
      <TimeSlider />
      <h2 className="mt-10 mb-2">Performance</h2>
      <p className="mb-4">
        Ability to load/render large volume of data points. (Refer Highcharts
        <a
          className="hover:underline text-blue-400 pointer"
          rel="noreferrer"
          href="https://www.highcharts.com/docs/advanced-chart-features/boost-module"
          target="_blank"
        >
          {" "}
          Boost module.
        </a>
        )
      </p>
      <LargeVolumeDataPointChart />
    </div>
  );
};

export { ChartsDisplayLayout };
