import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import StockTools from 'highcharts/modules/stock-tools';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import { Popup } from './Popup/Popup';
import { 
    DEFAULT_BORDER_WIDTH, 
    DEFAULT_FONT_SIZE, 
    LABEL,
    PREDEFINED_BACKGROUND_COLORS, 
    PREDEFINED_TEXT_COLORS,
} from './Popup/constants';

AnnotationsAdvanced(Highcharts);
StockTools(Highcharts);

const DrawingOnChart = () => {
    const chartRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [labelText, setLabelText] = useState('');
    const [selectedColor, setSelectedColor] = useState(PREDEFINED_BACKGROUND_COLORS[0]);
    const [selectedFontSize, setSelectedFontSize] = useState(DEFAULT_FONT_SIZE);
    const [shapeType, setShapeType] = useState('');
    const [strokeColor, setStrokeColor] = useState(PREDEFINED_TEXT_COLORS[1]);
    const [strokeWidth, setStrokeWidth] = useState(DEFAULT_BORDER_WIDTH);

    const showPopup = () => {
        setIsPopupVisible(true);
    };

    const hidePopup = () => {
        const { chart } = chartRef.current;
        setIsPopupVisible(false);
        chart.currentAnnotation = null;
        chart.navigationBindings.deselectAnnotation();
    };

    const handleStrokeWidthChange = (event) => {
        setStrokeWidth(event.target.value)
    }

    const handleLabelTextChange = (event) => {
        setLabelText(event.target.value);
    }

    const handleFontSizeChange = (event) => {
        setSelectedFontSize(event.target.value);
    };

    const handleSaveClick = (event, shapeType) => {
        const { chart } = chartRef.current;
        if (shapeType === LABEL) {
            chart.currentAnnotation.update({
                labels: [{
                    format: labelText,
                    backgroundColor: selectedColor,
                    style: {
                        color: strokeColor,
                        fontSize: `${selectedFontSize}`,
                    }
                }]
            });
        } else {
            chart.currentAnnotation.update({
                shapes: [{
                    fill: selectedColor,
                    stroke: strokeColor,
                    strokeWidth: strokeWidth
                }]
            });
        }
        hidePopup();
    };

    const handleEditClick = (shapeTypeLabel) => {
        const chart = chartRef.current.chart;

        // Remove existing popup if any
        const existingPopup = chart.container.querySelector('.custom-popup');
        if (existingPopup) {
            existingPopup.remove();
        }

        // Hide the default popup.
        const defaultPopup = document.querySelector('.highcharts-popup.highcharts-no-tooltip.highcharts-no-mousewheel');
        if (defaultPopup) {
            if (!defaultPopup.classList.contains('highcharts-annotation-toolbar')) {
                defaultPopup.style.display = 'none';

                while (defaultPopup.firstChild) {
                    defaultPopup.removeChild(defaultPopup.firstChild)
                }
            }
        }

        
        if (shapeTypeLabel === LABEL) {
            const currentLabel = chart.currentAnnotation.labels[0];
            const label = currentLabel.options.format === '{y:.2f}' ? currentLabel.points[0].y.toFixed(2) : currentLabel.options.format;

            const fontColor = currentLabel.options.style.color === '#FFFFFF' ? 'rgb(255, 255, 255)' : currentLabel.options.style.color;

            const fontSize = currentLabel.options.style.fontSize === '0.7em' ? '12' : currentLabel.options.style.fontSize; 
            setLabelText(label);
            setSelectedFontSize(fontSize);
            setStrokeColor(fontColor);
            setSelectedColor(currentLabel.options.backgroundColor);
        } else {
            const currentShape = chart.currentAnnotation.shapes[0].options;
            const borderColor = currentShape.stroke === 'rgba(0, 0, 0, 0.75)' ? 'rgb(0, 0, 0)' : currentShape.stroke;
            setStrokeColor(borderColor);
            setStrokeWidth(currentShape.strokeWidth);
            setSelectedColor(currentShape.fill);
        }


        showPopup();
    };

    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.chart.destroy();
            }
        };
    }, []);

    const options = {
        chart: {
            defaultShapes: {
                label: {
                    backgroundColor: 'yellow',
                    style: {
                        color: 'red'
                    },
                    shape: 'rect'
                }
            }
        },
        title: {
            text: 'Custom Shapes Popup'
        },
        navigation: {
            events: {
                showPopup(event) {
                    const chart = this.chart;
                    if (
                        event.formType === 'annotation-toolbar' &&
                        !this.chart.activeButton
                    ) {
                        const editButton = document.querySelector('.highcharts-annotation-edit-button');
                        if (editButton) {
                            Highcharts.addEvent(editButton, 'click', function (editEvent) {
                                chart.currentAnnotation = event.annotation;
                                if (event.annotation.basicType) {
                                    setShapeType(event.annotation.basicType);
                                } else {
                                    setShapeType(() => event.annotation.options.type);
                                }
                                const shapeTypeLabel = event.annotation.basicType || event.annotation.options.type
                                handleEditClick(shapeTypeLabel);
                            });
                        }
                    } else {
                        const defaultPopup = document.querySelector('.highcharts-popup.highcharts-no-tooltip.highcharts-no-mousewheel');

                        if (defaultPopup) {
                            defaultPopup.style.display = 'none';
                        }
                    }
                },
                selectButton(event) {
                    this.chart.activeButton = event.button;
                },
                deselectButton(event) {
                    this.chart.currentAnnotation = null;
                    this.chart.navigationBindings.deselectAnnotation();
                    this.chart.activeButton = null;
                }
            }
        },
        stockTools: {
            gui: {
                enabled: true,
                buttons: [
                    'simpleShapes'
                ]
            }
        },
        series: [{
            data: [1, 2, 3, 4, 5],
        }],
    };

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
            {isPopupVisible && chartRef.current && createPortal(
                <Popup
                    onClose={hidePopup}
                    labelText={labelText}
                    handleLabelTextChange={handleLabelTextChange}
                    selectedFontSize={selectedFontSize}
                    handleFontSizeChange={handleFontSizeChange}
                    setSelectedColor={setSelectedColor}
                    handleStrokeWidthChange={handleStrokeWidthChange}
                    setStrokeColor={setStrokeColor}
                    handleSaveClick={handleSaveClick}
                    shapeType={shapeType}
                    strokeWidth={strokeWidth}
                    selectedColor={selectedColor}
                    strokeColor={strokeColor}
                />,
                chartRef.current.chart.container
            )}
        </>
    );
};

export { DrawingOnChart };
