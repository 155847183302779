import { LabelOptions } from "./LabelOptions/LabelOptions";
import { ShapesOptions } from "./ShapesOptions/ShapesOptions";
import { LABEL } from "./constants";

const Popup = ({
    shapeType, selectedColor, strokeColor, labelText, selectedFontSize, strokeWidth,
    setSelectedColor, setStrokeColor, handleSaveClick, handleStrokeWidthChange,
    handleLabelTextChange, handleFontSizeChange, onClose
}) => (
    <div className="highcharts-popup-annotations absolute left-1/2 transform -translate-x-1/2 w-110 bg-white border border-slate-500 rounded p-4 top-6">
        {shapeType === LABEL
            ? <LabelOptions
                shapeType={shapeType}
                selectedColor={selectedColor}
                strokeColor={strokeColor}
                labelText={labelText}
                selectedFontSize={selectedFontSize}
                setSelectedColor={setSelectedColor}
                setStrokeColor={setStrokeColor}
                handleSaveClick={handleSaveClick}
                handleLabelTextChange={handleLabelTextChange}
                handleFontSizeChange={handleFontSizeChange}
                onClose={onClose}
              />
            : <ShapesOptions
                shapeType={shapeType}
                selectedColor={selectedColor}
                strokeColor={strokeColor}
                strokeWidth={strokeWidth}
                setSelectedColor={setSelectedColor}
                setStrokeColor={setStrokeColor}
                handleSaveClick={handleSaveClick}
                handleStrokeWidthChange={handleStrokeWidthChange}
                onClose={onClose}
              />
        }
    </div>
);

export { Popup };